/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, SeparateLine } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Akce v MŠ"}>
        <Column className="--center" style={{"paddingBottom":7,"paddingTop":21}} name={"hyvbumy01b"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"paddingRight":79,"paddingLeft":86}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Říjen v mateřské škole&nbsp;🪁"}>
              </Title>

              <Text className="text-box text-box--center" content={"Další měsíc je za námi. V říjnu se v mateřské škole udála spousta krásných věcí, o které bychom se s Vámi chtěli podělit. Děti si už zvykly, jak to v mateřské škole chodí, mají tady kamarády a společně si užíváme každý den. Třetí říjnový týden jsme objevovali svět z výšky spolu s papírovými dráčky. Děti se učily z čeho draka vyrobit, jak takový drak vypadá. Společně s dráčky, kteří ve školce nechyběli a dávali na děti bedlivý pozor, jsme se naučili nové básničky a písničky. Týden jsme završili pouštěním draků na poli, které si děti užily. Dráčkům se do oblak nejprve moc nechtělo, ale nakonec si přeci jen dali říct.&nbsp;23. 10. 2024 jsme pak pro rodiče v mateřské škole uspořádali přednášku na téma logopedická prevence s paní Ladou Halfarovou. Tato přednáška byla hrazena z projektu OP JAK. <br><br>Od listopadu mají děti v mateřské škole také možnost navštěvovat kroužek Mluvílek, který je zaměřen právě na logopedickou prevenci u dětí. V závěru měsíce se ve školce uskutečnil vzdělávací program Lékárnické pohádky a děti si mohly zhlédnout pohádku O černém bacilu.\n\nVšem rodičům a dětem přejeme krásné podzimní dny a budeme se těšit na další společné zážitky v mateřské škole."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l12w" style={{"paddingBottom":8,"paddingRight":81,"paddingLeft":90,"paddingTop":29}} name={"egc9b9bebea"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13139/419b5080784c40ec82e96821b13482e3_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13139/419b5080784c40ec82e96821b13482e3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13139/419b5080784c40ec82e96821b13482e3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13139/419b5080784c40ec82e96821b13482e3_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13139/5b38b2034a4443a7a8028ac08a5f5cfb_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13139/5b38b2034a4443a7a8028ac08a5f5cfb_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13139/5b38b2034a4443a7a8028ac08a5f5cfb_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13139/5b38b2034a4443a7a8028ac08a5f5cfb_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13139/5b38b2034a4443a7a8028ac08a5f5cfb_s=1400x_.jpeg 1400w"} style={{"maxWidth":297}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13139/68482c7fe7874e49ac3e5bc3e2dfb4f6_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13139/68482c7fe7874e49ac3e5bc3e2dfb4f6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13139/68482c7fe7874e49ac3e5bc3e2dfb4f6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13139/68482c7fe7874e49ac3e5bc3e2dfb4f6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13139/68482c7fe7874e49ac3e5bc3e2dfb4f6_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13139/6ef654dedd3c45a9921f652ddf24f73a_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13139/6ef654dedd3c45a9921f652ddf24f73a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13139/6ef654dedd3c45a9921f652ddf24f73a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13139/6ef654dedd3c45a9921f652ddf24f73a_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pl--25 pr--25 pt--10" name={"[[T00name]]"} layout={"l1"}>
        </Column>


        <SeparateLine className="css-q8ux01 css-ns06ek pt--10" style={{"marginBottom":0,"paddingBottom":41}} name={"a11ohitk6f"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/1de43e6fa9224dd7a137c827ae534bfc_s=3000x_.png);
    }
  
background-position: 50% 84%;
    `}>
        </SeparateLine>


        <Column style={{"paddingBottom":24,"paddingTop":38}} name={"rsauiujzrg"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"☀️ Září v mateřské škole&nbsp;🍂"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":1000,"paddingRight":119,"paddingLeft":117}} content={" Měsíc září se většinou nese v duchu velkých změn. Z některých dětí, které ještě před prázdninami navštěvovaly mateřskou školu, se stávají velcí školáci a do školky naopak přicházejí nové děti. Na začátku září jsme přivítali kromě dětí i novou paní ředitelku Mgr. Kamilu Onderkovou. V průběhu měsíce září jsme se seznamovali s novými kamarády a užívali si krásné podzimní počasí při hrách na školní zahradě. <br><br>Stejně jako v minulých letech jsme se i letos zapojili do sportovního programu Se sokolem do života a do programu Celé Česko čte dětem. Jako každý rok sbíráme pro zvířátka na zimu kaštany a žaludy. Vyrábíme z nasbíraných přírodnin a zapojíme se do krajské výtvarné soutěže „Barevný podzim“. Všem dětem a rodičům přejeme spoustu krásných chvil v nadcházejícím školním roce a budeme se těšit na všechna společná setkání ať už v mateřské škole nebo během jiných aktivit."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60" style={{"paddingTop":6,"paddingRight":146,"paddingLeft":143}} name={"9kybd7lbvi"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13139/a5138d06bfe04332a7b65e54dbbec024_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13139/a5138d06bfe04332a7b65e54dbbec024_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13139/a5138d06bfe04332a7b65e54dbbec024_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13139/a5138d06bfe04332a7b65e54dbbec024_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13139/a5138d06bfe04332a7b65e54dbbec024_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/13139/a5138d06bfe04332a7b65e54dbbec024_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13139/cd48fb314d5d4c5197c1f88805e049e2_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13139/cd48fb314d5d4c5197c1f88805e049e2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13139/cd48fb314d5d4c5197c1f88805e049e2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13139/cd48fb314d5d4c5197c1f88805e049e2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13139/cd48fb314d5d4c5197c1f88805e049e2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13139/cd48fb314d5d4c5197c1f88805e049e2_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13139/fb325108ac354be1bcc54128f5f78e97_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13139/fb325108ac354be1bcc54128f5f78e97_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13139/fb325108ac354be1bcc54128f5f78e97_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13139/fb325108ac354be1bcc54128f5f78e97_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13139/fb325108ac354be1bcc54128f5f78e97_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}